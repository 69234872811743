import dataStorage from '@/../utils/dataStorage.js'
import request from '@/../utils/request'
const api_name = '/iotechserver/userInfo'
export default {
    getUserInfo(username, password) {
        return request({
            url: `${api_name}/getUserInfo/${username}/${password}`,
            method: 'get'
        })
    },

    // 分页查询用户信息
    pageUserInfo(current, limit) {
        return request({
            url: `${api_name}/pageUserInfo/${current}/${limit}`,
            method: 'get'
        })
    },

    // 删除
    removeUserInfo(username) {
        return request({
            url: `${api_name}/removeUserInfo/${username}`,
            method: 'delete'
        })
    },

    // 添加
    addUserInfo(username, password) {
        return request({
            url: `${api_name}/addUserInfo`,
            method: 'post',
            data: {
                username: username,
                password: password,
            }
        })
    },




    login(username, password) {
        let res = request({
            url: `/login`,
            method: 'post',
            data: {
                username: username,
                password: password
            }
        })

        res.then(ress => {
            dataStorage.setBaseData("iotechtj_token", ress.data.data.token)
        })



        return res
    },

    logout() {
        let res = request({
            url: `/admin/logout`,
            method: 'post',
        })
        res.then(ress => {})
        return res
    },


    // 获取当前登录用户的信息，要显示的菜单
    loginUserInfo() {
        return request({
            url: `${api_name}/loginUserInfo`,
            method: 'get'
        })
    },
}