<template>
  <div id="app" class="url" style="padding: 15px">
    <el-row>
      <el-col :span="24" style="margin-bottom: 10px">
        <el-page-header @back="goBack" content="账号管理"> </el-page-header>
      </el-col>
    </el-row>


    <el-button type="success" style="margin-bottom:10px" class="el-icon-plus" @click="dialogVisible = true"> 添加账号</el-button></el-button>

    <el-dialog
      title="添加账号"
      :visible.sync="dialogVisible"
      width="25%">


      <div class="box">
        <el-tag class="item">账 &nbsp 号</el-tag>
        <el-input class="item-inp" v-model="username" placeholder="请输入用户名"></el-input>
      </div>

      <div class="box">
        <el-tag class="item">密 &nbsp 码</el-tag>
        <el-input class="item-inp" v-model="password" placeholder="请输入密码"></el-input>
      </div>

      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary"  @click="addUser">确 定</el-button>
      </span>
    </el-dialog>


    <template>
      <el-table :data="userInfo" style="width: 100%" height="500">
        <el-table-column
          prop="date"
          label="序号"
          type="index"
          width="120"
          align="center"
        >
        </el-table-column>
        <el-table-column prop="username" label="账号" align="center">
        </el-table-column>

        <el-table-column fixed="right" label="操作" width="160" align="center">
          <template slot-scope="scope">
            <el-button type="danger" size="mini" icon="el-icon-delete" @click="removeUser(scope.row)">删除</el-button>
          </template>
        </el-table-column>

      </el-table>
    </template>




    <!-- 分页 -->
    <el-row>
      <el-pagination
        :current-page.sync="current"
        :page-size="limit"
        :total="total"
        layout="total, prev, pager, next, jumper"
        @current-change="getUser"
      ></el-pagination>
    </el-row>




  </div>
</template>
<script>

import user from "@/api/manageApi/User";


export default {
  data() {
    return {
      dialogVisible:false,
      current: 1,
      limit: 5,
      total: 0,

      userInfo:[],

      username:"",
      password:"",

      
      
    };
  },

  created() {
    this.getUser()
  },

  methods: {
    // 返回上一级
    goBack() {
      this.$router.push("/Select");
    },

    getUser(){
      user.pageUserInfo(this.current,this.limit).then(res => {
        // console.log(res);
        this.userInfo = res.data.data.rows;
        this.total = res.data.data.total;
      })
    },

    removeUser(row){

      this.$confirm("此操作将永久删除该数据, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        //点击确定，指定then方法
        user.removeUserInfo(row.username).then((res) => {
          //提示信息
          this.$message({
            type: res.data.success ? "success" : "warning",
            message: res.data.message,
          });
          //回到页面并刷新
          this.getUser();
        });
      });
    },

    addUser(){
      if(this.username == "" || this.password == ""){
        this.$message.error("用户名或密码不能为空！");
      } else {
        user.addUserInfo(this.username,this.password).then(res =>{
          console.log(res);
            this.dialogVisible = false;
            this.username = "";
            this.password = "";
            this.getUser();
        })
      }
    },

  },
};
</script>

<style lang="scss" scoped>
.box{
  display: flex;
}
.box:nth-child(1){
  margin-bottom: 10px;
}
.item{
  width: 70px;
  font-size: 16px;
  height: 40px;
  line-height: 40px;
  margin-right: 10px;
}
.item-inp{
  float: right;
}

</style>